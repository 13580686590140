
import { Options, Vue } from 'vue-class-component'
import NaviBar from '@/components/NaviBar.vue'
import service from '@/utils/request'
import { Toast } from 'vant'

@Options({
  components: {
    NaviBar
  }
})
export default class DiaryWrite extends Vue {
  text = ''
  fileList = []
  checked = false
  taskId = ''
  planId = ''
  isDaily = false
  isNormal = false

  mounted () {
    console.log('this.$route.params == ', this.$route.params)
    const param = this.$route.params
    if (JSON.stringify(param) === '{}') {
      this.isNormal = true
    } else {
      this.taskId = this.$route.params.taskId as string
      this.planId = this.$route.params.planId as string
      this.isDaily = (this.$route.params.isDaily as string) === '1'
    }
  }

  afterRead (file: any) {
    console.log(file)
    Toast.loading({
      message: '上传图片',
      duration: 30000,
      mask: true
    })
    service.post('/files/upload/base64-image', { imageValue: file.content }).then(res => {
      console.log(res.data)
      file.url = res.data.url
      Toast.loading({
        message: '上传完成',
        duration: 1000
      })
    })
  }

  tapSubmit () {
    if (this.text.length < 5) {
      Toast.fail({
        message: '请输入内容不得少于5个字',
        duration: 2000
      })
      return
    }
    console.log('this.taskId', this.taskId)
    console.log('this.planId', this.planId)
    const picArr: any[] = []
    this.fileList.forEach((item: any) => {
      console.log('item == ', item)
      const pic = { pic: item.url }
      picArr.push(pic)
    })

    if (this.isNormal) {
      service.post('/mindup/mini/notes/submit', {
        content: this.text,
        isSendTeacher: this.checked,
        pics: picArr
      }).then(res => {
        Toast.success({
          message: '发布成功',
          duration: 2000
        })
        setTimeout(() => {
          this.$router.push({ name: 'UserInfo', params: { tabbar: 'diary' } })
        }, 2000)
      })
    }

    if (this.taskId) {
      service.post('/mindup/mini/tasks/' + this.taskId + '/task-note/submit', {
        content: this.text,
        isSendTeacher: this.checked,
        pics: picArr
      }).then(res => {
        Toast.success({
          message: '发布成功',
          duration: 2000
        })
        setTimeout(() => {
          this.$router.push({ name: 'Practice' })
        }, 2000)
      })
    }
    if (this.planId) {
      service.post('/mindup/mini/plans/' + this.planId + '/plan-note/submit', {
        content: this.text,
        isSendTeacher: this.checked,
        pics: picArr
      }).then(res => {
        Toast.success({
          message: '发布成功',
          duration: 2000
        })
        setTimeout(() => {
          this.$router.push({ name: 'UserInfo', params: { tabbar: 'diary' } })
        }, 2000)
      })
    }
  }
}
